import ValidatorClass from './ValidatorClass'

export default new ValidatorClass({
    FirstName: {
        name: 'First name',
        required: true,
        format: /^(?=.{1,16}$)[a-zA-ZāēīōūĀĒĪŌŪàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]+((\ |\'|\-)?[a-z A-ZāēīōūĀĒĪŌŪàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð])*$/,
        custom_message: 'Please, enter valid first name',
    },

    LastName: {
        name: 'Last name',
        required: true,
        format: /^(?=.{1,16}$)[a-zA-ZāēīōūĀĒĪŌŪàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]+((\ |\'|\-)?[a-z A-ZāēīōūĀĒĪŌŪàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð])*$/,
        custom_message: 'Please, enter valid last name',
    },

    Email: {
        name: 'E-mail',
        required: true,
        format: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
        custom_message: 'Please, enter valid email address',
    },

    Address: {
        name: 'Address',
        required: true,
        custom_message: 'Please, enter valid address',
    },

    City: {
        name: 'City',
        required: true,
        custom_message: 'Please, enter valid city',
    },

    PostalCode: {
        name: 'Postal code',
        required: true,
        format: /^\d+$/,
        custom_message: 'Please, enter valid postal code',
    },

    Country: {
        name: 'Country',
        required: true,
        custom_message: 'Please, enter valid country',
    },

    PhoneNumber: {
        name: 'Phone number',
        required: true,
        replaces: [
            {
                format: /^\+?\d{0,4}\(?\d{1,4}\)?[-\s\d]*$/,
                pattern: /[^\d]/g,
                replace: '',
            },
        ],
        format: /^\+?\d{9,12}$/,
        custom_message: 'Please, enter valid phone number',
    },

    NameServer: {
        name: 'Name server',
        required: true,
        custom_message: 'Please, enter valid name server',
    },
})