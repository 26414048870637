<template>
    <transition name="err">
        <div class="app-error" v-show="value || hasDefaultSlot">
            <div class="msg">
                {{ message || value || '' }}
                <slot></slot>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: ['value', 'message'],
    computed: {
        hasDefaultSlot () {
            return !!this.$slots.default
        },
    }
}
</script>

<style lang="scss">
.app-error {
    width: 100%;
    height: auto;
    font-size: 20px;
    line-height: 1.1;
    color: var(--color-error-darker);
    padding: 14px;
    background: var(--color-error-bg);
    transition: $transition-duration-primary;
    text-align: center;
    border-radius: $border-radius-primary;

    &.err-enter-active, &.err-leave-active {
        transition: all #{$transition-duration-primary / 2} linear;

        transform: rotateX(0deg);
        padding: 14px 14px;
    }
    
    &.err-enter, &.err-leave-to {
        transition: all #{$transition-duration-primary / 2} linear;

        transform: rotateX(90deg);
        padding: 0px 14px;
    }
}

@media (max-width: $tablet-size) {
    .app-error {
        font-size: 16px;
        line-height: 18px;
        padding: 8px;
    }
}

@media (max-width: $mobile-size) {}
</style>
